import React from "react"
import Comment from './Comment.js';
import '../App.scss';

class WorkingHours extends React.Component {
  render() {
    return (
        <div className='section mt-80 invisible six'>
          <div className='section-header'>
            <div className="mr-32">Work</div>
            <div className="mt-12">↓</div>
          </div>

          <div className='section-item d-flex pt-12'>
            <div className="time w-120 subdued w-120 pr-32">2021 – today</div>
            <div className="description">
              <div>Principal Product Experience Designer</div>
              <div className="subdued pt-4"><a href="https://www.dynatrace.com/" target="_blank">Dynatrace</a> (Linz, Austria)</div>
              <div className="subdued pt-12">Right now, I’m leading the design team focused on Dashboards, Notebooks, and the platform experience.</div>
            </div>
          </div>

          <div className='section-item d-flex pt-40'>
            <div className="time w-120 subdued w-120 pr-32">2015 – 2021</div>
            <div className="description">
              <div>Senior UX Designer & UX Lead</div>
              <div className="subdued pt-4"><a href="https://www.capgemini.com/" target="_blank">Capgemini</a> (Stuttgart, Germany)</div>
              <div className="subdued pt-12">An intrapreneurial journey of building and scaling an innovation lab and its team. Primary customer: Mercedes-Benz.</div>
            </div>
          </div>

          <Comment text="I’ve worked with both small startups and large companies, contributing as a designer and leading teams. My background in software engineering, design, and strategy helps me navigate the balance between business goals, design vision, and technical realities to create solutions that work."/>
      </div>
    )
  }
}
export default WorkingHours