import React from "react"
import Comment from './Comment.js';
import '../App.scss';

class Education extends React.Component {
  render() {
    return (
        <div className='section mt-80 invisible eight'>
        <div className='section-header'>
          <div className="mr-32">Education</div>
          <div className="mt-12">↓</div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2015 – 2017</div>
          <div className="description">
            <div>Computer Science and Media (MSc.)</div>
            <div className="subdued pt-4">Stuttgart Media University</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2012 – 2015</div>
          <div className="description">
            <div>Mobile Media (BSc.)</div>
            <div className="subdued pt-4">Stuttgart Media University</div>
          </div>
        </div>

      </div>
    )
  }
}
export default Education